import { useRouter } from 'next/router'
import React from 'react'
import { toast } from 'react-toastify'
import useSWRImmutable from 'swr/immutable'

import { appActions } from '@features/app/reducers'
import { GetEmployerProfileAPIResponseData } from '@features/kyc/types'

import RepostModal from '@components/modal/repost-modal'

import { useAppDispatch, useAppSelector } from '@hooks/redux'

import { CLICK_ON_CONFIRM_REPOST_JOB } from '@constants/event-tracking'
import MODAL_NAME from '@constants/modal-name'
import { PATHS } from '@constants/paths'
import { EMPLOYERS_PROFILE_URL } from '@constants/url'

import errorFormat from '@utils/error-format'
import trackEvents from '@utils/track-events'

import { useRepostJobActions } from '@store/jobs/repost'

const RepostJobModal = () => {
  const dispatch = useAppDispatch()
  const modalJobId = useAppSelector((state) => state.jobReducer.modalId)
  const modalName = useAppSelector((state) => state.appReducer.modalName)
  const router = useRouter()
  const repostJobActions = useRepostJobActions()

  const { data: employerProfileData } =
    useSWRImmutable<GetEmployerProfileAPIResponseData>(EMPLOYERS_PROFILE_URL)
  const eventProperties = {
    category: employerProfileData?.isPhantom ? 'MS' : 'JobProvider',
    employerId: employerProfileData?.id,
  }
  const onClickConfirmRepostJob = async () => {
    try {
      trackEvents(CLICK_ON_CONFIRM_REPOST_JOB, eventProperties)
      dispatch(appActions.removeModal())
      repostJobActions.setPage('review')

      router.push({
        pathname: PATHS.REPOST_JOB,
        query: {
          jid: modalJobId,
        },
      })
    } catch (reRegisterError) {
      toast.error(errorFormat(reRegisterError))
    }
  }
  return (
    <RepostModal
      open={modalName === MODAL_NAME.REPOST_MODAL}
      onClose={() => dispatch(appActions.removeModal())}
      onSubmit={onClickConfirmRepostJob}
    />
  )
}

export default RepostJobModal
