import { post } from '@helpers/fetch-wrapper'
import useSWRImmutable from 'swr/immutable'

import { INITIALIZE_URL } from '@constants/url'

export const StaticLocationFeatureToggle = ({
  children,
}: {
  children?: React.ReactNode
}) => {
  const { data: initializeData } = useSWRImmutable<{
    featureToggle: {
      staticLocation: boolean
    }
  }>(INITIALIZE_URL, async (url: string) => {
    const response = await post(url)
    return response.data
  })

  if (!initializeData?.featureToggle.staticLocation) return null

  return children
}

export default StaticLocationFeatureToggle
