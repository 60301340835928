/* eslint-disable @typescript-eslint/no-explicit-any */
import { Popover, Transition } from '@headlessui/react'
import Image from 'next/image'
import { useRouter } from 'next/router'
import React, { Fragment } from 'react'

type Props = {
  jobId: string
}
const JobCardMenuToggle = (props: Props) => {
  const router = useRouter()
  const { jobId } = props
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={` ${open ? '' : 'text-opacity-90'} group py-2 z-50 block rounded-md bg-transparent text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 hover:text-opacity-100 lg:inline-flex lg:items-center lg:space-x-2`}
          >
            <Image
              alt="icon"
              src="/assets/icons/ic_meetballs_menu.svg"
              width={22}
              height={22}
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-5 z-20 my-2 w-max -translate-x-full transform xl:left-8">
              <div className="w-[200px] overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="relative bg-white">
                  <button
                    type="button"
                    className="mx-auto flex w-full items-stretch border-b py-3 transition duration-150 ease-in-out focus:outline-none focus-visible:ring focus-visible:ring-primary-blue focus-visible:ring-opacity-50 hover:bg-[#F4F6FB]"
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      router.push(`/jobs/edit?jlid=${jobId}`)
                    }}
                  >
                    <Image
                      src="/assets/icons/ic_edit_template.svg"
                      width={18}
                      height={18}
                      alt="edit job listing"
                      className="ml-2"
                      style={{
                        maxWidth: '100%',
                        height: 'auto',
                      }}
                    />
                    <p className="ml-2 text-[#4460AC]">Edit</p>
                  </button>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}

export default JobCardMenuToggle
