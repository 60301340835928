/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from '@helpers/axios-instance'
import {
  authHeader,
  get,
  handleResponse,
  patch,
  post,
  put,
} from '@helpers/fetch-wrapper'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'

import { BRANCH_COMPLETE_REGISTRATION } from '@constants/branch-event'
import {
  COMPANY_BYID_URL,
  COMPANY_CONTACT_URL,
  EMPLOYERS_PROFILE_URL,
  GET_INDUSTRIES_URL,
  POST_COMPANY_LOGO_URL,
} from '@constants/url'

import { setBranchEvent } from '@utils/branch'

import { CompanyProps } from '@custom-types/company'

import { PostProfileProps } from './types'

export const getContact = createAsyncThunk(
  'profile/getContact',
  async (companyId: string, { rejectWithValue }) => {
    try {
      const res = await get(COMPANY_CONTACT_URL(companyId))
      return { type: res.data.type, value: res.data.value }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getProfile = createAsyncThunk(
  'profile/getProfile',
  async (_, thunkApi) => {
    try {
      thunkApi.getState()
      // const companyId = (state as RootState)?.profileReducer?.company
      // 	?.id as string

      const res = await get(EMPLOYERS_PROFILE_URL)
      // if (companyId) {
      // 	await thunkApi.dispatch(getContact(companyId)).unwrap()
      // }

      return res.data
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  }
)

export const postProfile = createAsyncThunk(
  'onboard/postProfile',
  async (profileData: PostProfileProps, thunkApi) => {
    try {
      const res = await post(EMPLOYERS_PROFILE_URL, profileData)
      const userData = res?.data

      await setBranchEvent(BRANCH_COMPLETE_REGISTRATION, {
        fullName: userData?.fullName,
        companyName: userData?.company?.name,
        industry: userData?.company?.industry?.name,
      })

      const companyId = res?.data?.company?.id

      if (companyId) {
        await thunkApi.dispatch(getContact(companyId)).unwrap()
      }

      return res.data
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  }
)

export const updateProfile = createAsyncThunk(
  'profile/updateProfile',
  async (profileData: Partial<PostProfileProps>, thunkApi) => {
    try {
      const res = await patch(EMPLOYERS_PROFILE_URL, profileData)
      const { data } = res

      return data
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  }
)

export const updateCompany = createAsyncThunk(
  'profile/updateCompany',
  async (
    companyData: Partial<Omit<CompanyProps, 'id' | 'employee'>>,
    thunkApi
  ) => {
    try {
      const state = thunkApi.getState()

      const companyId = (state as any).profileReducer?.company?.id as string

      await put(COMPANY_BYID_URL(companyId), companyData)
      return companyData
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  }
)

export const uploadLogo = createAsyncThunk(
  'profile/uploadLogo',
  async (logoData: File, thunkApi) => {
    try {
      const state = thunkApi.getState()
      const companyId = (state as any).profileReducer?.company?.id as string
      const config = {
        headers: {
          ...authHeader(),
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest',
        },
      }

      const formData = new FormData()
      formData.append('image', logoData)
      const res = await axios.post(
        POST_COMPANY_LOGO_URL(companyId),
        formData,
        config
      )

      return (handleResponse(res) as AxiosResponse).data
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  }
)

export const getIndustries = createAsyncThunk(
  'profile/getIndustries',
  async (_, { rejectWithValue }) => {
    try {
      const res = await get(GET_INDUSTRIES_URL)
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
